import React from "react";
import { Switch } from "react-router-dom";
import Blogs from "./../components/Blogs";
import Blog from "./../components/Blogs/Blog";
import Dashboard from "./../components/Dashboard";
import Login from "./../components/Login";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRedirectRoute from "./ProtectedRedirectRoute"
import ProtectedLoginRoute from "./ProtectedLoginRoute"
import "./../assets/css/material-kit-react.css";

export class RootRoute extends React.Component {

    render() {
        return (
            <Switch>
                <ProtectedLoginRoute path="/login" component={Login} />
                <ProtectedRedirectRoute exact path="/" />
                <ProtectedRoute exact path="/dashboard" data-name="Главная" component={Dashboard} />
                <ProtectedRoute exact path="/blog" data-name="Блог" component={Blogs} />
                <ProtectedRoute exact path="/blog/:id/:lang" data-name="Блог" component={Blog} />
            </Switch>
        );
    }
}
