import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Dashboard extends React.Component {
    render() {
        return <div></div>;
    }
}

const widthConnect = connect(
    state => ({ user: state.user.user }),
    dispatch => bindActionCreators({  }, dispatch)
);

export default widthConnect(Dashboard)
