import { blogConstant as Types } from "./../constants";
import axios from "axios";

function Init() {
    return (dispatch, getState) => {
        const { blog, user } = getState();
        const pagination = blog.pagination.activePage;
        // poka tak
        const limit = 12 * pagination;
        const skip = 12 * (pagination - 1);
        // :(
        const domain = window.location.host.split(".")[window.location.host.split(".").length -1].split(":")[0];
        axios
            .get(`https://front-api.soroban.ua/api/blog/${domain}?limit=${limit}&skip=${skip}&token=!Aa12345&notcache=${+new Date()}`)
            .then(response => {
                if (response.status === 200 && response.data) {
                    const dataResponse = response.data;
                    dataResponse.blogs.map(blog => {
                        const lang = blog.language.toLocaleLowerCase();
                        blog.title = blog.title[lang];
                        blog.digest = blog.digest[lang];
                        blog.content = blog.content[lang];
                    });
                    dispatch({
                        type: Types.INIT,
                        blog: {
                            data: dataResponse.blogs,
                            pagination: { ...blog.pagination, total: dataResponse.total },
                            loading: true
                        }
                    });
                    dispatch({
                        type: Types.INITEDIT,
                        blogEdit: { loading: false, redirect: false }
                    });
                }
            });
    };
}

function setLang(lang, id){
    return (dispatch, getState) => {
        const {  blogEdit } = getState();
        dispatch({
            type: Types.SETLANG,
            blogEdit: { ...blogEdit, lang }
        });
        InitEdit(id, lang)(dispatch, getState)

    }
}

function InitEdit(id, lang) {
    return (dispatch, getState) => {
        console.log('InitEdit');
        const { blog, blogEdit } = getState();
        const domain = window.location.host.split(".")[window.location.host.split(".").length -1].split(":")[0];
        if (id) {
            if (blog.loading) {
                const blogData = blog.data.filter(blog => blog.post_name === id && blog.language === lang.toUpperCase())[0];
                dispatch({
                    type: Types.INITEDIT,
                    blogEdit: { data: blogData, loading: true, redirect: false }
                });
            } else {
                axios
                    .get("https://front-api.soroban.ua/api/blog/"+domain+"/single/" + id + `?token=!Aa12345&notcache=${+new Date()}&lang=${lang.toUpperCase()}`)
                    .then(response => {

                        if (response.status === 200 && response.data) {
                            const dataResponse = response.data;
                            dispatch({
                                type: Types.INITEDIT,
                                blogEdit: { data: {language: lang.toUpperCase() , ...dataResponse }, loading: true, redirect: false }
                            });
                        }
                    });
            }
        } else {
            console.log('InitEdit', InitEdit);
            dispatch({
                type: Types.INITEDIT,
                blogEdit: { loading: true, redirect: false }
            });
        }
    };
}

function SaveBlog() {
    return (dispatch, getState) => {
        const { blogEdit, user } = getState();
        // console.log(blogEdit)
        axios({
            method: "post",
            url: "https://front-api.soroban.ua/api/blog/",
            data: blogEdit.data,
            headers: { auth: user.info }
        }).then(function(response) {
            if (response.data.response) {
                dispatch({
                    type: Types.INITEDIT,
                    blogEdit: { ...blogEdit, redirect: true }
                });
            }
        });
    };
}

function setPage(page) {
    return (dispatch, getState) => {
        const { blog } = getState();
        dispatch({
            type: Types.SET_PAGINATION,
            blog: { ...blog, pagination: { ...blog.pagination, activePage: page }, loading: true }
        });
        Init()(dispatch, getState);
    };
}

function deleteBlog(id) {
    return (dispatch, getState) => {
        const {  user } = getState();
        const domain = window.location.host.split(".")[window.location.host.split(".").length -1].split(":")[0];
        axios({
            method: "delete",
            url: `https://front-api.soroban.ua/api/blog/${domain}/${id}?token=!Aa12345`,
            headers: { auth: user.info },
            config: { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        }).then(function(response) {
            if (response) {
                Init()(dispatch, getState);
            }
        });
    };
}

function SetEdit(obj) {
    return (dispatch, getState) => {
        const { blogEdit } = getState();
        dispatch({
            type: Types.SET,
            blogEdit: { ...blogEdit, data: { ...blogEdit.data, ...obj } }
        });
    };
}

export { Init, InitEdit, SetEdit, SaveBlog, deleteBlog, setPage, setLang };
