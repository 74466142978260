import { blogConstant as Types } from "./../constants";

const defaultState = {
    loading: false,
    data: [],
    pagination: {
        activePage: 1
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.INIT:
        case Types.SET_PAGINATION:
            return { ...state, ...action.blog };
        default:
            return state;
    }
};
