import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";

import { compose, combineReducers, createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {createBrowserHistory} from 'history';

import { connectRouter ,routerMiddleware , ConnectedRouter } from 'connected-react-router';


import { RootRoute } from './routes';

import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('user_info');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (ex) {
    return undefined;
  }
};
const persistedState = loadState();


const _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	combineReducers({...rootReducer, router: connectRouter (history)}),
	persistedState,
	_compose(applyMiddleware(routerMiddleware(history)), applyMiddleware(thunk))
);

ReactDOM.render(
	  <Provider store={store}>
		<ConnectedRouter history={history}>
		  <RootRoute />
		</ConnectedRouter>
	  </Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
