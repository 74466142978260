import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

class ProtectedLoginRoute extends Component {
    render() {
        const isAuthenticated = this.props.isAuthenticated;
        const Component = this.props.component;
        const path = this.props.path;
        return isAuthenticated ? (
            <Redirect to="/" />
        ) : (
            <Route
                path={path}
                render={props => {
                    return <Component {...props} />;
                }}
            />
        );
    }
}

const mapStateToProps = state => {
    return { isAuthenticated: state.user && state.user.loggedin };
};
export default connect(
    mapStateToProps,
    {}
)(ProtectedLoginRoute);
