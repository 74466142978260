import React from "react";

import { Init, deleteBlog, setPage } from "./../../actions/blog";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import Card from "./../elements/Card/Card.jsx";
import CardBody from "./../elements/Card/CardBody.jsx";
import Button from "./../elements/CustomButtons/Button.jsx";
import Pagination from "react-js-pagination";

class Blogs extends React.Component {
    constructor(props) {
        super(props);
        this.props.Init();
    }
    deletePostClick = id => {
        this.props.deleteBlog(id);
    };
    handlePageChange = pageNumber => {
        this.props.setPage(pageNumber)
    };
    render() {
        console.log('123213');
        const listItems = this.props.blog.data.map(blog => (
            <Card key={blog.id.toString()} style={{ width: "23%" }}>
                <div onClick={this.deletePostClick.bind(false, blog.id)} className="deleted-post">
                    X
                </div>
                <img
                    style={{ height: "100px", width: "100%", display: "block" }}
                    className="imgCardTop"
                    src={blog.image}
                    alt="Card-img-cap"
                />
                <CardBody>
                    <h4 className="cardTitle">{blog.title}</h4>
                    <p>{blog.digest}</p>
                    <Button color="primary">
                        <Link to={"/blog/" + blog.post_name + "/ro"}>Просмотр</Link>
                    </Button>
                </CardBody>
            </Card>
        ));
        return (
            <div>
                <div className="container-blog"> {listItems} </div>
                <Pagination
                    activePage={this.props.blog.pagination.activePage}
                    itemsCountPerPage={12}
                    totalItemsCount={this.props.blog.pagination.total}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                />
            </div>
        );
    }
}
// activePage={this.state.activePage}
const widthConnect = connect(
    state => ({ blog: state.blog }),
    dispatch => bindActionCreators({ Init, deleteBlog, setPage }, dispatch)
);

export default widthConnect(Blogs);
