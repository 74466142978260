import React from "react";
import { connect } from "react-redux";
import {  Redirect } from "react-router-dom";

class ProtectedRedirectRoute extends React.Component {
    render() {
        const isAuthenticated = this.props.isAuthenticated;
        return isAuthenticated ? (
            <Redirect to="/dashboard" />
        ) : (
            <Redirect to="/login" />
        );
    }
}

const mapStateToProps = state => {
    return { isAuthenticated: state.user && state.user.loggedin };
};
export default connect(
    mapStateToProps,
    {}
)(ProtectedRedirectRoute);
