import { userConstant as Types } from "./../constants";

const defaultState = {
    info: null,
	loggedin: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.INIT:
        case Types.LOGIN:
        case Types.LOGOUT:
        case Types.SET:
            return { ...state, ...action.user };
        default:
            return state;
    }
};
