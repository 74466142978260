import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { InitEdit, SetEdit, SaveBlog, setLang } from "./../../../actions/blog";
import CustomInput from "./../../elements/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import FileBase64 from "react-file-base64";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "./../../elements/CustomButtons/Button.jsx";

import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
// import 'font-awesome/css/font-awesome.css';

import FroalaEditor from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

class Blog extends React.Component {
    constructor(props) {
        super(props);
        console.log('GO');
        if (this.props.match.params.id === "new") {
            console.log('NEW');
            this.props.InitEdit();
        } else this.props.InitEdit(this.props.match.params.id, this.props.match.params.lang);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.state = {
            test: null
        };
    }
    handleChange = e => {
        console.log({ [e.target.id]: e.target.value });
        this.props.SetEdit({ [e.target.id]: e.target.value });
    };
    handleModelChange = model => {
        this.props.SetEdit({ content_ru: model });
    };
    handleClickLogin = e => {
        e.preventDefault();
        this.props.SaveBlog();
    };
    onDrop = pictureFiles => {
        this.props.SetEdit({ image: pictureFiles.base64 });
    };
    handleEditorChange = content => {
        this.props.SetEdit({ content });
    };
    test = e => {
        const files = Array.from(e.target.files);
        console.log(files);
        const formData = new FormData();

        files.forEach((file, i) => {
            formData.append(i, file);
            console.log(formData);
        });
    };
    render() {
        console.log('123132');
        const blogData = this.props.blogEdit;
        if (!blogData.loading) return false;
        const blog = blogData.data;
        const isRedirect = this.props.blogEdit.redirect;
        const test = {
            toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction

                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ["clean"] // remove formatting button
            ]
        };
        return isRedirect ? (
            <Redirect to="/blog" />
        ) : (
            <div>
                <a href={"/blog/" + this.props.match.params.id +"/ru"}>RU</a>
                <span> --- </span>
                <a href={"/blog/" + this.props.match.params.id +"/ro"}>RO</a>
                <CustomInput
                    labelText="Название статьи"
                    id="title"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: blog.title,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon className="inputIconsColor">title</Icon>
                            </InputAdornment>
                        )
                    }}
                />
                <CustomInput
                    labelText="Краткое описание"
                    id="digest"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: blog.digest || "",
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon className="inputIconsColor">title</Icon>
                            </InputAdornment>
                        )
                    }}
                />
                <CustomInput
                    labelText="Ссылка"
                    id="post_name"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        value: blog.post_name || "",
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon className="inputIconsColor">link</Icon>
                            </InputAdornment>
                        )
                    }}
                />
                <div className="uploadImage">
                    <div className="image" style={{ backgroundImage: `url(${blog.image})` }} />
                    <label>
                        <p>Загрузить изображение</p>
                        <FileBase64 multiple={false} onDone={this.onDrop} />
                    </label>
                </div>
                <Editor
                    initialValue={blog.content}
                    onEditorChange={this.handleEditorChange}
                    apiKey="hh8mxug0z7gvfjlsj4gskv87kf5dryzuiqet2et1y73lhbg0"
                    init={{
                        paste_data_images: true,
                        height: 600,
                        images_upload_url: 'postAcceptor.php',
                        automatic_uploads: false,
                        plugins: "link image code",
                        toolbar:
                            "formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment"
                    }}
                />
                <Button onClick={this.handleClickLogin} simple color="primary" size="lg">
                    Сохранить
                </Button>
            </div>
        );
    }
}
// <Editor
//     initialValue={blog.content}
//     onEditorChange={this.handleEditorChange}
//     apiKey='hh8mxug0z7gvfjlsj4gskv87kf5dryzuiqet2et1y73lhbg0'
//     init={{
//         height: 600,
//         plugins: "link image code",
//         toolbar: 'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
//     }}
// />
const widthConnect = connect(
    state => ({ blog: state.blog, blogEdit: state.blogEdit }),
    dispatch => bindActionCreators({ InitEdit, SetEdit, SaveBlog, setLang }, dispatch)
);

export default widthConnect(Blog);
