import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import App from "./../containers/App";

class ProtectedRoute extends Component {
    render() {
        const isAuthenticated = this.props.isAuthenticated;
        const Component = this.props.component;
        const name = this.props["data-name"];
        const path = this.props.path;
        return isAuthenticated ? (
            <App name={name}>
                <Route
                    path={path}
                    render={props => {
                        return <Component {...props} />;
                    }}
                />{" "}
            </App>
        ) : (
            <Redirect to="/login" />
        );
    }
}

const mapStateToProps = state => {
    return { isAuthenticated: state.user && state.user.loggedin };
};
export default connect(
    mapStateToProps,
    {}
)(ProtectedRoute);
