import { userConstant as Types } from "./../constants";
import axios from "axios";

function Init() {
    return (dispatch, getState) => {
        const { user } = getState();
        const storageData = localStorage.getItem("user_info");
        if (storageData) {
            dispatch({ type: Types.INIT, user: { ...user.info, info: storageData } });
        } else dispatch({ type: Types.INIT, user: { ...user.info, user: { info: null } } });
    };
}

function login(data) { 
    return (dispatch, getState) => {
        const { user } = getState();
        // axios.post("https://front-api.soroban.ua/api/login?token=!Aa12345", data).then(response => {
        //     if (response.status === 200 && response.data) {
        //         const dataResponse = response.data;
        //         localStorage.setItem(
        //             "user_info",
        //             JSON.stringify({ user: { info: dataResponse.token, loggedin: dataResponse.success } })
        //         );
        //         dispatch({
        //             type: Types.LOGIN,
        //             user: { ...user.info, info: dataResponse.token, loggedin: dataResponse.success }
        //         });
        //     }
        // });
        // var bodyFormData = new FormData();
        // for(let i in data) bodyFormData.set(i, data[i]);
        axios({
            method: "post",
            url: "https://front-api.soroban.ua/api/login?token=!Aa12345",
            data: data,
            config: { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        }).then(function(response) {
            if (response.status === 200 && response.data) {
                const dataResponse = response.data;
                localStorage.setItem(
                    "user_info",
                    JSON.stringify({ user: { info: dataResponse.token, loggedin: dataResponse.success } })
                );
                dispatch({
                    type: Types.LOGIN,
                    user: { ...user.info, info: dataResponse.token, loggedin: dataResponse.success }
                });
            }
        });
        // const storageData = localStorage.getItem("user_info");
        // if(storageData){
        // dispatch({ type: Types.INIT, user: { ...user.info, info: storageData } });
        // } else dispatch({ type: Types.INIT, user: { ...user.info, user: {info: null} } });
    };
}

function logout() {
    return (dispatch, getState) => {
        const { user } = getState();
        const storageData = localStorage.getItem("user_info");
        if (storageData) {
            const localStorageDataUser = JSON.parse(storageData)["user"];
            localStorageDataUser.info = null;
            localStorageDataUser.loggedin = false;
            localStorage.setItem("user_info", JSON.stringify({ user: localStorageDataUser }));
            dispatch({
                type: Types.LOGOUT,
                user: { ...user.info, info: localStorageDataUser.info, loggedin: localStorageDataUser.loggedin }
            });
        }
    };
}

function Set(data) {
    return (dispatch, getState) => {
        const storageData = JSON.parse(localStorage.getItem("sms_data")) || [];
        if (data.length) {
            for (let sms of data) {
                storageData.push(sms);
            }
        }
        localStorage.setItem("sms_data", JSON.stringify(storageData));
    };
}

export { Init, Set, login, logout };
