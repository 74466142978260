import { blogConstant as Types } from "./../constants";

const defaultState = {
    loading: false,
    redirect: false,
    data: {
        title: "",
        content: "",
        digest: "",
        image: "https://uaprom-static.c.prom.st/image/new_design/images/no_image-hce614324446b22b42a09b69093e309fce.png",
        seo_url: ""
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.INITEDIT:
        case Types.SET:
            return { ...state, ...action.blogEdit, data: action.blogEdit.data || defaultState.data  };
        default:
            return state;
    }
};
